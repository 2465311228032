/* eslint-disable import/no-anonymous-default-export */
export default {
  title: "نتعاون لنحمي مناضلي الحريّة",
  donateNow: " تبرع/ي الآن",
  joinCampaign: "شارك/ي الحملة",
  mainGoalTitle: "هدفنا الحالي",
  reachedGoalTitle: "جمع لحد الآن",
  requestSupport: "بحاجة إلى دعم؟ الرجاء تعبئة الاستمارة الأولية وسنتواصل معك/ ي",
  currency: "ش.ج",
  headerLastTitle: "شكراً على الاهتمام والمساعدة",
  descriptionTextMain: 'نفّذت شرطة إسرائيل حملة اعتقالات سياسيّة عشوائيّة واسعة، في محاولةٍ لقمع وترهيب أبناء وبنات شعبنا الفلسطيني لمنعنا من التظاهر والاحتجاج.',
    descriptionText2:'حيث اعتقلت الشرطة الإسرائيليّة منذ بداية الاحتجاجات أكثر من 2000 فلسطينيّ في أراضي ال 48، من بينهم الأطفال والقاصرين، وقد تعرّض المعتقلون لانتهاكات سافرة واعتداءات جسديّة ونفسيّة مروّعة. وقد سجّل المحامون المتطوّعون مئات حالات العنف الجسديّ والنفسيّ بحق المعتقلين، لا سيما الأطفال.',
  descriptionText3: `نحن بأمس الحاجة لدعمكم وتبرّعكم من أجل تغطية تكاليف المحامين للدفاع عن المعتقلين الفلسطينيين ضد لوائح الاتهام الباطلة المقدّمة بحقّهم.`,
  campaignGoalsTitle: "أهداف الحملة",
  campaignGoalsText1: `1) توفير الدفاع القانوني لمعتقلي الكرامة والأمل.`,
  campaignGoalsText2: `2) حماية عائلات المعتقلين ومنع تدهور أوضاعهم الاقتصاديّة والاجتماعيّة بسبب العبء الماديّ وتكاليف المحامين.`,
  aboutusTitle: "من نحن",
  aboutusText1: `جمعيّة الشباب العرب "بلدنا" – هي منظمة شبابيّة عربيّة فلسطينيّة مستقلّة تأسست عام 2001. تنشط الجمعيّة في مجال العمل الشبابيّ، اجتماعيًا وسياسيًا وثقافيًا وتربويًا، على المستوى المحليّ والدوليّ، وتسعى إلى تطوير قيادات شبابيّة وتعزيز الهويّة العربيّة الفلسطينيّة على أسس ديمقراطيّة.`,
  aboutusText2: `في حملة الدفاع عن معتقلي الهبّة الشعبيّة، ستعمل جمعيّة "بلدنا" بالتعاون مع لجنة استشارية تطوعية ترافق الصندوق. تتألف اللجنة من محاميّات ومحامين في مجال حقوق الإنسان، ومجندي/ات موارد، وطاقم محاسبة وغيرهم.`,
  footerTitle: "تواصل/ي معـنا",
  footerMessageSend: "أرسـل/ي رسـالة",
  socialContainerTitle: `لزيارة صفحاتنا على مواقع التواصل الاجتماعـي`,
  approachTitle: "خطوط عريضة للعمل",
  approachText1:
      "خلال الهبّة الشعبيّة الفلسطينيّة في أيّار 2021، نفّذت الشرطة الإسرائيليّة حملة اعتقالات سياسيّة عشوائيّة واسعة. اعتقلت شرطة إسرائيل، في أراضي ال 1948، أكثر من 2000 فلسطينيّ/ة (حتّى 31.5.2021)، أغلبيّتهم الساحقة من فئتين عينيّتين؛ أطفال وقاصرين أولًا، وشباب من فئات تعيش ظروفًا اجتماعيّة واقتصاديّة صعبة ثانيًا.",
  approachText2:
      'خلال اعتقالهم، انتهكت الشرطة الإسرائيليّة حقوقهم الأساسيّة وسُجّلت حالات كثيرة من العنف الجسديّ والنفسيّ، بعضها يشكل تعذيبًا، لا سيما بحق الأطفال. كما تم تحويل جزء من المعتقلين إلى الشاباك ومنعهم من لقاء محاميهم. لا زالت حملة الاعتقالات مستمرّة حيث أعلنت الشرطة الإسرائيليّة في نهاية أيار عن عملية "قانون ونظام" لاعتقال 500 فلسطيني/ة بهدف "استعادة الرّدع" و"تصفية الحسابات" مع الشباب الفلسطينيين، في محاولة لإرهابهم و ثنيهم عن النضال.',
  approachText3:
      "في المرحلة الأولى من الاعتقال- الاستشارة القانونيّة في مركز الاعتقال ومحاكمات تمديد الاعتقال- نشط عشرات المحاميّات والمحامون الفلسطينيّات والفلسطينيّون في كل المدن والقرى، وقدّموا تمثيلًا قانونيًا بتطوعٍ تام ودون أي مقابلٍ ماديّ. إثر الترافع الأوليّ، أُطلق سراح الأغلبيّة الساحقة من المعتقلين، وهو ما يُثبت تعسّف الاعتقالات وعدم قانونيّتها.",
  approachText4:
      'مع هذا، قدّمت الشرطة الإسرائيليّة حتى الآن ما يقارب ال 200 لائحة اتّهام ضدّ شابّات وشبّان في مقتبل العمر، وضد أطفال قاصرين، في محاولة لتجريمهم بسبب مشاركتهم بمظاهرات ونضالات قمعتها الشرطة الإسرائيلية بهمجيّة. في ظلّ هذه التطوّرات أُقيم "صندوق الكرامة والأمل للدّفاع عن المعتقلين"، للعمل على ضمان حق معتقلي الهبّة الشعبيّة داخل أراضي 1948 بالحصول على التمثيل القانوني أمام لوائح الاتهام التي قدّمتها النيابة العامة الإسرائيليّة. ومن أجل الدفاع عن حقوقهم الأساسيّة، وعلى رأسها الحق بالحريّة، الحقّ بالتنظيم والاحتجاج كما الحق في التمثيل القانوني والحق في الإجراءات القانونية العادلة.',

approachText5:
    'تشكّل الصندوق تحت مظلّة جمعيّة الشباب العرب "بلدنا"، وسيستمرّ عمله حتّى منتصف العام 2022، حيث سيتم تقييم وتلخيص العمل ونقاش مستقبل الصندوق.',

    subTitle1: "أهداف الصندوق ", 
    subTitle1Option1: "1) توفير التمثيل القانوني لمعتقليّ الكرامة والأمل.",
    subTitle1Option2:
"2) حماية العائلات من تدهورٍ اقتصاديّ بسبب الأعباء الماديّة الناجمة عن اعتقال أحد أفرادها.",
    subTitle2: "أولويّات المشروع",
    subTitle2Option1:
"سيعمل الصندوق على تأمين التمثيل القانونيّ للمعتقلين وفق المُتاح من موارد ماليّة وبشريّة، وتعطى الأولويّة لمعالجة قضايا فئتين من المعتقلين:",
    subTitle2Option2: "1) الأطفال والقاصرين.",
    subTitle2Option3:
"2) الشباب من المناطق والفئات الأشد تهميشًا اقتصاديًا واجتماعيًا. ",
    subTitle3: "اللجنة الاستشارية",
    subTitle3Option1:
"مع انطلاق الصندوق في نهاية شهر حزيران 2021، تشكّلت لجنة استشارية تطوعية ترافق الصندوق. تتألف اللجنة من محاميّات ومحامين في مجال حقوق الإنسان، ومجندي/ات موارد، وطاقم محاسبة وغيرهم.",
    subTitle4: " مسؤوليّات اللجنة",
    subTitle4Option1:
'1) جمع وبحث التوجّهات من المعتقلين أو عائلات المعتقلين ل "صندوق الكرامة والأمل للدّفاع عن المعتقلين" لتمثيلهم ضد لوائح الاتهام، قبول التوجّهات أو الاعتذار عنها (مع تعليل سبب الرفض).',
    subTitle4Option2:
'2) في حال عدم توفر محامي، إحالة الملف المصادق عليه إلى محامي/ة توفره/ا اللجنة الاستشارية من طاقم مرافعة مختص في مجالات قانونية متعلقة بلوائح الاتهام.',
    subTitle4Option3:
"3) التواصل مع اللجان الشعبيّة المحليّة التي تولّت الدفاع عن معتقلي بلدتها، وتحديد مسار عيني لدعم كل لجنة وفق احتياجاتها وضمن الموارد الماليّة والبشريّة المتاحة. ",
    subTitle4Option4:
"4) تحديد مسار للمساهمة بتكلفة التمثيل القانوني من قبل محامين خاصين تم تعيينهم من قبل العائلات وذلك وفق معايير اجتماعيّة اقتصاديّة واضحة.",
    subTitle4Option5:
"في جميع الحالات، سيتم تحويل الدعم المادي مباشرة للمحامي المكلّف بالتمثيل القانوني.",
    subTitle4Option6:
'5) متابعة سير لوائح الاتهام وتوفير الاستشارة والحلول الممكنة في حال ظهور أية تساؤلات أو إشكاليّات.',
    subTitle4Option7:
'6) الحرص على استخدام موارد "صندوق الكرامة والأمل للدّفاع عن المعتقلين" بنجاعةٍ وشفافيّة ونزاهة.',
    subTitle4Option8:
"7) بعد ختام المشروع، منتصف عام 2022، ستعمل اللجنة على إصدار تقرير عمل وتقرير إداري يفصّل ويقيّم سير المشروع ونتائجه، يقدّم كشفًا نزيهًا وشفافًا لمدخولات المشروع ومصروفاته.",
subTitle5: " تنظيم المكافأة المالية للمحامين المترافعين من قبل الصندوق",
    subTitle5Option1:
"تحرص اللجنة على إعطاء جميع المحامين حقّهم المادّي لقاء عملهم بشكل عادل ومتساوي، ووفق معايير محددة وواضحة ومنشورة.",
    subTitle5Option2:
'نظرًا للحالة الطارئة والحاجة الملحّة لبدء العمل، ستعتمد اللجنة على نظام المكافأة الماليّة المتّبع لدى "الدفاع العام" (סניגוריה ציבורית)، والذي يكافئ بموجب عدد الجلسات والإجراءات وطبيعة الملفّات، مع إجراء تعديلات بسيطة كالآتي: ',
    subTitle5Option3:
"- لا يتعدّى المبلغ النهائيّ للمحامي أكثر من 12,000 شيكل، الا في حالات استثنائية تقررها اللجنة بشكل عيني.",
    subTitle5Option4:
"- أضافة للمكافأة الماليّة المتعلّقة بالإجراء القانونيّ، ووفقا للإمكانيات المادية، ستتم تغطية مصاريف إضافية ناتجة عن السفريات.",
    subTitle5Option5:
"- هذه المبالغ قابلة للتغيير وفقًا للوضع المادي للصندوق وذلك بعد قرار مُفصّل من اللجنة القانونية وبعد استشارة خبير مالي.",
    subTitle6: "التكاليف الإداريّة",
    subTitle6Option1:
"يُسمح بتخصيص ما لا يتعدّى 5% من موارد الصندوق لإدارة وتمكين عمل الصندوق واللجنة، أو دفع تكاليف تقنيّة ولوجستيّة. تُغطّى هذه التكاليف من موارد الصندوق في حال تعذّر سدّها من مساهمات طوعيّة خارجيّة. تتّخذ اللجنة قرار تخصيص موارد للتكاليف الإداريّة من الصندوق وفق أهداف ومبالغ محددة.",
}
