/* eslint-disable import/no-anonymous-default-export */
export default {
  title: "United We Stand for Dignity and Hope",
  donateNow: " Donate Now",
  joinCampaign: "Share",
  mainGoalTitle: "Goal",
  reachedGoalTitle: "Raised",
  requestSupport: "In need of support? Please fill in this form and we’ll be in touch",
  currency: "US Dollar",
  headerLastTitle: "Thank you for your support",
  descriptionTextMain: "In an attempt to intimidate, terrorize, and deter Palestinians from participating in their struggle for freedom, the Israeli police have been carrying out mass and indiscriminate political arrests.",
  descriptionText2: "Over  2000 Palestians citizens of Israel -- including minors and children -- have been unlawfully and violently detained by Israeli police since mass protests erupted across Palestine in May  2021. Volunteer-led legal teams have documented hundreds of horrific cases of physical, legal, and psychological abuse of the detainees, many of whom are minors and children." ,
  descriptionText3: 'Help us raise the funds necessary to cover Palestinian defendants legal defense costs, and to challenge false indictments in Israeli courts.',
  campaignGoalsTitle: "Our goals are to:",
  campaignGoalsText1: ` 1) Provide legal representation for Dignity and Hope defendants.`,
  campaignGoalsText2: `2) Support the detainees’ families, many of whom already live in dire socio-economic conditions, by ensuring that they do not have to bear the financial burden of these legal costs alone.`,
  aboutusTitle: "Who are we?",
  aboutusText1: `We are Baladna -- an independent Palestinian Arab youth organization founded in 2001. We focus on social, political, cultural, and educational youth organising at the local and international levels in order to foster youth leadership, strengthen Arab Palestinian identity, and build a well-informed and resilient Palestinian society.`,
  aboutusText2: `As part of the campaign to defend the detainees of the mass uprising, Baladna Association will work with a voluntary Advisory Committee that was formed to accompany the project. The Committee is composed of specialized lawyers with extensive experience in human rights law, fundraisers, accountants and others.`,
  footerTitle: "Contact Us",
  footerMessageSend: "Email Us",
  socialContainerTitle: "Visit Our Social Media Pages",
  approachTitle: "Working Guidelines",
  approachText1: "During the Palestinian popular uprising in May 2021, the Israeli police carried out widespread and arbitrary political arrests. The Israeli police arrested more than 2,000 Palestinians citizens of Israel (as of 31 May 2021) most of which are either children under the age of 18 or youth from a socially and economically vulnerable background.",
  approachText2:
    'During their detention, the Israeli police violated their basic rights, and many cases of physical and psychological violence, some of which amount to torture, including against children, were recorded. In addition, some of the detainees were detained and interrogated by the Shin Bet (Israeli Security Agency) and prevented from meeting their lawyers. The arrest campaign continues. At the end of May, the Israeli police launched what it called a "Law and Order" campaign to "restore deterrence", which in effect aims to deter Palestinians protesting and resisting ongoing repression.',
  approachText3:
    "In the first phase of detention - legal assistance in the detention center and detention extension hearings - dozens of Palestinian lawyers across all cities and villages mobilized, and provided pro bono (free of charge) legal representation. Following the initial appearances, the overwhelming majority of the detainees were released, further evidence of the arbitrariness and illegality of the arrests.",
  approachText4:
    'Nevertheless, the Israeli police continue to pursue more than 200 criminal indictments against young women and men, and against minor children. Consequently, the "Dignity and Hope Detainees Fund" was established to provide legal representation for the accused to defend their basic rights; their right to freedom, their right to organize and protest, as well as the right to legal representation and the right to due process.',
approachText5: "In light of the above, the \"Dignity and Hope Detainees Fund\", formed under the umbrella of “Baladna” - The Arab Youth Association. The initiative aims to defend Palestinian citizens of Israel who were arrested during the popular uprising in May 2021, by providing free legal representation in response to the indictments submitted against them by the Israeli prosecution. The initiative will continue until June 2022, at which point its work will be evaluated and the future discussed.",
  subTitle1: "The Fund’s Objectives",
  subTitle1Option1: "1) Provide legal representation for Dignity and Hope defendants.",
  subTitle1Option2:
    "2) Protect the defendants’ families from the economic hardship that flows from the detentions and prosecutions of their loved ones.",
  subTitle2: "Funding Priorities",
  subTitle2Option1:
    "The Fund aims to provide legal representation to all defendants, according to available financial and human resources. The Fund will prioritize handling the cases of:",
  subTitle2Option2: "1) Children and minors;",
  subTitle2Option3:
    "2) Youth from the most economically and socially marginalized or vulnerable regions and groups. ",
  subTitle3: "Advisory Committee",
  subTitle3Option1:
    "With the launch of the project at the end of June 2021, a voluntary  Advisory Committee was formed to accompany the project. The Committee is composed of specialized lawyers with extensive experience in human rights law, fundraisers, accountants and others.",
  subTitle4: " Committee responsibilities",
  subTitle4Option1:
    "1) Collecting and investigating the requests for legal defense submitted by defendants or their families to the “Dignity and Hope Fund,” approved or rejected requests will be explained.",
  subTitle4Option2:
    '2) In cases where a lawyer is yet to be appointed, the case will be referred to a lawyer appointed by the Advisory Committee with a specialised lawyer.',
  subTitle4Option3:
    "3) Communicating with local popular committees that support the defendants from their villages or towns, and coordinating support with each committee according to its needs and within the available financial and human resources.",
  subTitle4Option4:
    "4) The Committee will put forth a mechanism to contribute to the cost of private legal counsel appointed by the defendants’ families, in accordance with set and transparent socio-economic criteria.",
  subTitle4Option5:
    "In all cases, financial support will be transferred directly to the attorney legally representing the detainee.",
  subTitle4Option6:
    '5) Follow up on the progress of the indictments and offer advice and solutions to any questions or problems related to the cases.',
subTitle4Option7: 
"6) Ensure that the \"Dignity and Hope Fund\" resources are used with efficiency, transparency and integrity.",
subTitle4Option8:
"7) When the project concludes, by mid-2022, a report detailing and evaluating the Fund’s progress, providing a complete and transparent account of income and expenditures, as well as reviewing and evaluating the initiative’s results.",
  subTitle5: "Administration of legal fees and attorney compensation by the Fund",
  subTitle5Option1:
    "The committee is committed to financially compensating all lawyers in exchange for their services in a fair and equitable manner, in accordance with well-defined and transparent criteria.",
  subTitle5Option2: 'Considering the urgency of the situation and the pressing need to commence the work, the Committee will structure legal fees in ways that are similar to that of a “public defender” (סניגוריה ציבורית), which compensates lawyers according to the number of hearings, filings and other procedures, as well as the nature of the charges, with the following adjustments:',
  subTitle5Option3:
    "- The maximum compensation allocated for each lawyer shall not exceed 12,000 N.I.S. (~3,680 USD), except in special cases decided by the Committee on a case-by-case basis.",
  subTitle5Option4:
    "- In addition to compensating lawyers for their legal services, given availability of funds, travel expenses will be covered.",
  subTitle5Option5:
    "- The allocated amounts are subject to change based on the financial status of the Fund, and only after a detailed decision by the legal Committee followed by a financial-expert consultation.",
  subTitle6: " Administrative Costs",
  subTitle6Option1:
    "No more than 5% of the Fund's resources may be allocated to cover the Fund or the Committee’s administrative, technical or logistical fees. The Fund's resources will be used to cover such costs only if they cannot be met through external contributions. The Committee shall decide to allocate resources to cover administrative costs for limited goals and with limited amounts.",
}
